<script lang="ts" setup>
import type { akButton } from '~/interfaces/ak.interface'

const props = defineProps<{
  button: akButton
}>()

const color = computed(() => {
  if (props.button.type) {
    if (props.button.type === 'Bleu') return 'primary'
    else if (props.button.type === 'Vert') return 'secondary'
    else if (props.button.type === 'Vert avec bordure') return 'secondary'
    else if (props.button.type === 'Blanc') return 'white'
  }
})

const variant = computed(() => {
  if (props.button.type) {
    if (props.button.type === 'Vert avec bordure') return 'outline'
  }
})

const size = computed(() => {
  if (props.button.size) {
    if (props.button.size === 'Normal') return 'md'
    else if (props.button.size === 'Grand') return 'lg'
  }
})

const goSection = () => {
  const element = document.querySelector(props.button.external.replace('/', ''))
  if (element) {
    const y = element.getBoundingClientRect().top + window.scrollY - 100
    window.scrollTo({ top: y, behavior: 'smooth' })
  }
}
</script>

<template>
  <UButton
    v-if="button.external && button.external.startsWith('#')"
    :variant="variant"
    :color="color"
    :label="button.label"
    :size="size"
    @click="goSection"
  />
  <UButton
    v-else
    :variant="variant"
    :color="color"
    :label="button.label"
    :size="size"
    :target="button.newTab ? '_blank' : undefined"
    data-test="button"
    :to="
      button.external
        ? button.external
        : button.page && button.page.data
          ? `/${button.page.data.attributes.slug}`
          : undefined
    "
  />
</template>
